import { z } from "zod"

import { sbPluginIntegrationShopifyVideos } from "~/lib/storyblok/schemas/plugins/integration-shopify-data-field"
import { enumWithDefaultSchema } from "~/lib/zod/utils/enum-with-default"
import { hexaColorSchema } from "~/lib/zod/utils/hexa-color-schema"
import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { sbAssetSchema } from "~/components/ui/Asset/_data/schema"
import { imageSchema } from "~/components/ui/Image/_data/schema"
import { richtextSchema } from "~/components/ui/RichText/_data/schema"
import { roundedCtaSchema } from "~/components/ui/RoundedCta/_data/schema"
import { defaultSliceSchema } from "~/components/slices/constants"

export const editoTextImageTightSliceSchema = defaultSliceSchema("edito_text_image_tight").extend({
  background_color: hexaColorSchema.default("#F1EEE9"),
  suptitle: richtextSchema.nullish(),
  title: richtextSchema,
  paragraph: richtextSchema.nullish(),
  image: imageSchema,
  asset: optionalTupleSchema(sbAssetSchema).nullish(),
  is_reversed: z.boolean().optional().default(false),
  mobile_text_first: z.boolean().optional().default(false),
  cta: optionalTupleSchema(roundedCtaSchema),
  text_color: enumWithDefaultSchema(["light", "dark"], "dark"),
  videos: optionalTupleSchema(sbPluginIntegrationShopifyVideos).nullish(),
})
