"use client"

import clsx from "clsx"

import type { InferReturn } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import RoundedCta from "~/components/ui/RoundedCta"
import RichText from "~/components/abstracts/RichText"
import { Stack } from "~/components/abstracts/Stack"
import { editoTextListImageSliceSchema } from "~/components/slices/EditoTextListImage/_data/schema"
import serializeEditoTextListImageSlice from "~/components/slices/EditoTextListImage/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type EditoTextListImageProps = InferReturn<typeof serializeEditoTextListImageSlice>

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

function EditoTextListImage({
  suptitle,
  title,
  listItems,
  cta,
  image,
  isReversed,
  mobileTextFirst,
  isFirstSlice,
}: EditoTextListImageProps) {
  return (
    <div
      className={clsx(
        css.EditoTextListImage,
        isReversed ? css.position["isReversed"] : null,
        mobileTextFirst ? css.position["mobileTextFirst"] : null,
        isFirstSlice ? css.sliceState["isFirstSlice"] : null
      )}
      data-comp="Slices/EditoTextListImage"
    >
      <div className={css.left}>
        <div className={css.leftWrapper}>
          <RichText render={suptitle} className={css.suptitle} />
          <RichText render={title} className={css.title} />
          {listItems.length > 0 && (
            <ul className={css.list}>
              {listItems.map((item, i) => {
                const number = i + 1
                return (
                  <li key={`list-item-${i}`} className={css.item}>
                    <Stack
                      gap={5}
                      direction={"row"}
                      className={text({
                        design: "riposte-14",
                        color: "blue-light-txt",
                        weight: "light",
                        lineHeight: 1.45,
                        letterSpacing: 0.2,
                      })}
                    >
                      <span className={css.number}>{number < 9 ? `0${number}` : number}</span>

                      <RichText render={item} className={css.richText} />
                    </Stack>
                  </li>
                )
              })}
            </ul>
          )}
          {cta && (
            <div className={sprinkles({ textAlign: { mobile: "center", tablet: "left" } })}>
              <RoundedCta className={css.cta} {...cta} width="full" size="large" />
            </div>
          )}
        </div>
      </div>
      <div className={css.right}>
        <Image {...image} ratio={"1/1"} sizesFromBreakpoints={IMAGE_SIZES} asPlaceholder />
      </div>
    </div>
  )
}

export { EditoTextListImage, type EditoTextListImageProps }

export default WithSafeSliceData(
  async function (props) {
    const serializedProps = await serializeEditoTextListImageSlice(props, props?.locale)

    return <EditoTextListImage {...serializedProps} />
  },
  editoTextListImageSliceSchema,
  "EditoTextListImage"
)
