import type { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { rTFilledOrNull } from "~/lib/storyblok/utils/check-empty-string"
import { serializeSbAsset } from "~/components/ui/Asset/_data/serializer"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeRoundedCta from "~/components/ui/RoundedCta/_data/serializer"
import type { editoTextImageSliceSchema } from "~/components/slices/EditoTextImage/_data/schema"

type EditoTextImageSliceSchema = z.infer<typeof editoTextImageSliceSchema>

export default async function serializeEditoTextImageSlice(slice: EditoTextImageSliceSchema, locale: Nullish<string>) {
  return {
    isReversed: slice.is_reversed,
    mobileTextFirst: slice.mobile_text_first,
    backgroundColor: slice.background_color,
    suptitle: rTFilledOrNull(slice.suptitle),
    paragraph: rTFilledOrNull(slice.paragraph),
    title: rTFilledOrNull(slice.title),
    image: serializeImage(slice.image),
    asset: slice.asset ? serializeSbAsset(slice.asset[0]) : null,
    cta: await serializeRoundedCta(slice.cta[0], locale),
    isGreaterHeight: slice.is_greater_height,
    textTheme: slice.text_color,
    isFirstSlice: slice.isFirstSlice,
  }
}
