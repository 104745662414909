"use client"

import { clsx } from "clsx"

import type { InferReturn } from "~/@types/generics"
import { Asset } from "~/components/ui/Asset"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import { VideoWithRatio } from "~/components/ui/VideoMobileDesktop"
import { editoTextImageTightSliceSchema } from "~/components/slices/EditoTextImageTight/_data/schema"
import serializeEditoTextTightImageSlice from "~/components/slices/EditoTextImageTight/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import { hideFrom, showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text, type TextVariants } from "~/styles/variants"

import * as css from "./styles.css"

type EditoTextImageTightProps = InferReturn<typeof serializeEditoTextTightImageSlice>

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

function EditoTextImageTight({
  suptitle,
  title,
  paragraph,
  image,
  asset,
  videos,
  isReversed,
  mobileTextFirst,
  backgroundColor,
  cta,
  textTheme,
  isFirstSlice,
}: EditoTextImageTightProps) {
  const suptitleAndTitleTextColor: NonNullable<TextVariants>["color"] =
    textTheme === "dark" ? "blue-deep" : "grey-bg-page"

  const paraphraphTextColor: NonNullable<TextVariants>["color"] =
    textTheme === "dark" ? "blue-light-txt" : "grey-bg-page"

  return (
    <div
      className={clsx(
        css.EditoTextImageTight,
        isReversed ? css.position["isReversed"] : null,
        mobileTextFirst ? css.position["mobileTextFirst"] : null,
        isFirstSlice ? css.sliceState["isFirstSlice"] : null
      )}
      data-comp="Slices/EditoTextImageTight"
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "",
      }}
    >
      <div className={css.left}>
        {videos.desktop && videos.mobile ? (
          <>
            <VideoWithRatio
              className={clsx(hideFrom("mobile", "block"), showFrom("tablet", "block"))}
              ratio={"579/800"}
              video={{ ...videos.desktop, fill: true }}
            />
            <VideoWithRatio
              className={clsx(hideFrom("tablet", "block"), showFrom("mobile", "block"))}
              ratio={"375/628"}
              video={{ ...videos.mobile, fill: true }}
            />
          </>
        ) : asset ? (
          <Asset
            {...asset}
            ratio={{ tablet: "579/900", mobile: "375/628" }}
            sizesFromBreakpoints={IMAGE_SIZES}
            asPlaceholder
          />
        ) : (
          <Image
            {...image}
            ratio={{ tablet: "579/900", mobile: "375/628" }}
            sizesFromBreakpoints={IMAGE_SIZES}
            asPlaceholder
          />
        )}
      </div>
      <div className={css.right}>
        <div className={css.rightWrapper}>
          {suptitle && (
            <RichText
              className={text({
                design: "riposte-13",
                transform: "uppercase",
                weight: "medium",
                letterSpacing: 1,
                color: suptitleAndTitleTextColor,
              })}
              render={suptitle}
            />
          )}
          <RichText
            className={clsx(
              text({
                design: "spezia-32",
                transform: "uppercase",
                letterSpacing: 0.4,
                color: suptitleAndTitleTextColor,
              }),
              css.title
            )}
            render={title}
          />
          {paragraph && (
            <RichText
              className={clsx(
                text({
                  design: "riposte-14",
                  lineHeight: 1.45,
                  color: paraphraphTextColor,
                }),
                css.paragraph
              )}
              render={paragraph}
            />
          )}
          {cta && <RoundedCta className={css.cta} {...cta} size="large" />}
        </div>
      </div>
    </div>
  )
}

export { EditoTextImageTight, type EditoTextImageTightProps }

export default WithSafeSliceData(
  async function (props) {
    const serializedProps = await serializeEditoTextTightImageSlice(props, props?.locale)

    return <EditoTextImageTight {...serializedProps} />
  },
  editoTextImageTightSliceSchema,
  "EditoTextImageTightSlice"
)
