"use client"

import { clsx } from "clsx"

import type { InferReturn } from "~/@types/generics"
import { Asset } from "~/components/ui/Asset"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import { editoTextImageSliceSchema } from "~/components/slices/EditoTextImage/_data/schema"
import serializeEditoTextImageSlice from "~/components/slices/EditoTextImage/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import { breakpoints } from "~/styles/variables/breakpoints"
import { text, type TextVariants } from "~/styles/variants"

import * as css from "./styles.css"

type EditoTextImageProps = InferReturn<typeof serializeEditoTextImageSlice>

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

function EditoTextImage({
  suptitle,
  title,
  paragraph,
  cta,
  image,
  asset,
  isReversed,
  mobileTextFirst,
  backgroundColor,
  isGreaterHeight,
  textTheme,
  isFirstSlice,
}: EditoTextImageProps) {
  const suptitleAndTitleTextColor: NonNullable<TextVariants>["color"] =
    textTheme === "dark" ? "blue-deep" : "grey-bg-page"

  const paragraphTextColor: NonNullable<TextVariants>["color"] =
    textTheme === "dark" ? "blue-light-txt" : "grey-bg-page"

  return (
    <div
      className={clsx(
        css.EditoTextImage,
        isReversed ? css.position["isReversed"] : null,
        mobileTextFirst ? css.position["mobileTextFirst"] : null,
        isFirstSlice ? css.sliceState["isFirstSlice"] : null
      )}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "",
      }}
      data-comp="Slices/EditoTextImage"
    >
      <div className={css.left}>
        <div className={clsx(css.leftWrapper)}>
          {suptitle && (
            <RichText
              className={text({
                design: "riposte-13",
                transform: "uppercase",
                weight: "medium",
                letterSpacing: 1,
                color: suptitleAndTitleTextColor,
              })}
              render={suptitle}
            />
          )}
          <RichText
            className={clsx(
              css.title,
              text({
                design: "spezia-32",
                transform: "uppercase",
                letterSpacing: 0.4,
                color: suptitleAndTitleTextColor,
              })
            )}
            render={title}
          />
          {paragraph && (
            <RichText
              className={clsx(
                css.paragraph,
                text({
                  design: "riposte-14",
                  lineHeight: 1.45,
                  color: paragraphTextColor,
                })
              )}
              render={paragraph}
            />
          )}
          {cta && <RoundedCta className={css.cta} {...cta} size="large" />}
        </div>
      </div>
      <div className={css.right}>
        {asset ? (
          <Asset
            {...asset}
            asPlaceholder
            ratio={{ tablet: isGreaterHeight ? "720/900" : "10/9", mobile: isGreaterHeight ? "375/600" : "5/8" }}
            sizesFromBreakpoints={IMAGE_SIZES}
          />
        ) : (
          <Image
            {...image}
            asPlaceholder
            ratio={{ tablet: isGreaterHeight ? "720/900" : "10/9", mobile: isGreaterHeight ? "375/600" : "5/8" }}
            sizesFromBreakpoints={IMAGE_SIZES}
          />
        )}
      </div>
    </div>
  )
}

export { EditoTextImage, type EditoTextImageProps }

export default WithSafeSliceData(
  async function (props) {
    const serializedProps = await serializeEditoTextImageSlice(props, props?.locale)

    return <EditoTextImage {...serializedProps} />
  },
  editoTextImageSliceSchema,
  "EditoTextImageSlice"
)
