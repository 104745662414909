import type { z } from "zod"

import type { Nullish } from "~/@types/generics"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeRoundedCta from "~/components/ui/RoundedCta/_data/serializer"
import type { editoTextListImageSliceSchema } from "~/components/slices/EditoTextListImage/_data/schema"

type EditoTextListImageSliceSchema = z.infer<typeof editoTextListImageSliceSchema>

export default async function serializeEditoTextListImageSlice(
  slice: EditoTextListImageSliceSchema,
  locale: Nullish<string>
) {
  return {
    suptitle: slice.suptitle,
    title: slice.title,
    image: serializeImage(slice.image),
    cta: await serializeRoundedCta(slice.cta[0], locale),
    isReversed: slice.is_reversed,
    mobileTextFirst: slice.mobile_text_first,
    listItems: slice.list_items?.map((item) => item.text) ?? [],
    isFirstSlice: slice.isFirstSlice,
  }
}
