import { z } from "zod"

import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { imageSchema } from "~/components/ui/Image/_data/schema"
import { richtextSchema } from "~/components/ui/RichText/_data/schema"
import { roundedCtaSchema } from "~/components/ui/RoundedCta/_data/schema"
import { defaultSliceSchema } from "~/components/slices/constants"

export const editoTextListImageSliceSchema = defaultSliceSchema("edito_text_list_image").extend({
  suptitle: richtextSchema.nullish(),
  title: richtextSchema.nullish(),
  cta: optionalTupleSchema(roundedCtaSchema),
  is_reversed: z.boolean().optional().default(false),
  mobile_text_first: z.boolean().optional().default(false),
  list_items: z.array(z.object({ text: richtextSchema })),
  image: imageSchema,
})
