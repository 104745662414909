import type { z } from "zod"

import type { Nullish } from "~/@types/generics"
import getSfMediaVideo from "~/lib/shopify/queries/get-sf-media-video"
import { rTFilledOrNull } from "~/lib/storyblok/utils/check-empty-string"
import { serializeSbAsset } from "~/components/ui/Asset/_data/serializer"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeRoundedCta from "~/components/ui/RoundedCta/_data/serializer"
import { serializeVideo } from "~/components/ui/Video/_data/serializer"
import type { editoTextImageTightSliceSchema } from "~/components/slices/EditoTextImageTight/_data/schema"

type EditoTextImageTightSliceSchema = z.infer<typeof editoTextImageTightSliceSchema>

export default async function serializeEditoTextImageTightSlice(
  slice: EditoTextImageTightSliceSchema,
  locale: Nullish<string>
) {
  const videoDesktop = await getSfMediaVideo(slice.videos?.[0]?.video_desktop?.id)
  const videoMobile = await getSfMediaVideo(slice.videos?.[0]?.video_mobile?.id)

  return {
    isReversed: slice.is_reversed,
    mobileTextFirst: slice.mobile_text_first,
    backgroundColor: slice.background_color,
    suptitle: rTFilledOrNull(slice.suptitle),
    paragraph: rTFilledOrNull(slice.paragraph),
    title: rTFilledOrNull(slice.title),
    image: serializeImage(slice.image),
    asset: slice.asset?.[0] ? serializeSbAsset(slice.asset?.[0]) : null,
    cta: await serializeRoundedCta(slice.cta[0], locale),
    textTheme: slice.text_color,
    videos: {
      desktop: serializeVideo(videoDesktop),
      mobile: serializeVideo(videoMobile),
    },
    isFirstSlice: slice.isFirstSlice,
  }
}
